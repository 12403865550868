import React from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";

const Navigation = () => {
  const { scrollY } = useViewportScroll();
  const background = useTransform(
    scrollY,
    [0, 100],
    ["rgba(0, 0, 0, 0)", "rgba(0, 0, 0, 1)"]
  );
  const height = useTransform(scrollY, [0, 100], [120, 60]);

  return (
    <motion.div
      id="navigation"
      style={{
        background,
        height
      }}
    >
      <div id="logo" > 🦋⃤♡⃤🌈⃤ </div>
    </motion.div>
  );
};

export default Navigation;
import React from "react";
import "./Header.css";
import Grid from '@mui/material/Grid2';

import { motion } from "framer-motion";

const Header = () => {
  return (
    <Grid container spacing={0} className="__section" alignContent={'center'}>
      <Grid size={{ sm: 12, md: 8 }} className="leftGrid" alignContent={"center"}>
        <motion.div
          // className="title"
          whileHover={{ scale: 1.1 }}
          initial={{ transform: "translateX(-100px)" }}
          animate={{ transform: "translateX(0px)" }}
          transition={{  duration: 1, type: "tween" }}
        >
          La Casa de Crea
        </motion.div>
      </Grid>
      <Grid size={{ sm: 12, md: 4 }}  className="rightGrid" alignContent={"center"}>
        <p>Your partner in creative marketing!</p>
      </Grid>
    </Grid>
  );
};

export default Header;

import './App.css';
import About from './components/About';
import Header from './components/Header';
import Navigation from './components/Navigation';
import Partners from './components/Partners';
import Rotate from './components/Rotate';
import Solutions from './components/Solutions';

function App() {
  return (
    <div className="App">
      <Navigation />
      <Header />
      <Rotate />
      <Solutions />
      <Partners />
      <About />
    </div>
  );
}

export default App;

import React from "react";
import "./Header.css";
import Grid from '@mui/material/Grid2';

import { motion } from "framer-motion";

const About = () => {
  return (
    <Grid container spacing={0} className="__section solution" alignContent={'center'}>
      <Grid className="leftGrid" alignContent={"center"}>
        <motion.div
          className="title"
          // initial={{ opacity: 0 }}
          // animate={{ opacity: 100 }}
          style={{textAlign: 'center'}}
          whileHover={{ scale: 1.1 }}
          initial={{ transform: "translateX(-100px)" }}
          whileInView={{ transform: "translateX(0px)" }}
          transition={{  duration: 2, type: "tween" }}
        >
          Coming Soon!
        </motion.div>
      </Grid>
    </Grid>
  );
};

export default About;
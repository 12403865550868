import React from "react";
import "./Header.css";
import Grid from '@mui/material/Grid2';

import { motion } from "framer-motion";

const Solutions = () => {
  return (
    <Grid container spacing={0} className="__section solution" alignContent={'center'}>
      <Grid size={{ sm: 12, md: 6 }} className="leftGridSolution" alignContent={"center"}>
        <div className="service">Brand Guideline</div>
        <hr />
        <div className="service">Content Creation</div>
        <hr />
        <div className="service">Social Media Management</div>
        <hr />
        <div className="service">SEO & Growth Measurement</div>
        <hr />
        <div className="service">A friend, a brother and what not!</div>
        <hr />
        <div className="service_last">We got you!</div>
        <motion.div
            animate={{
                x: 0,
                backgroundColor: "#000",
                boxShadow: "10px 10px 0 rgba(0, 0, 0, 0.2)",
                position: "fixed",
            }}
        >TADA</motion.div>
      </Grid>
      <Grid size={{ sm: 12, md: 6 }} className="rightGridSolution" alignContent={"center"}>
        <motion.div
          className="title"
          whileHover={{ scale: 1.1 }}
          initial={{ transform: "translateY(-100px)" }}
          whileInView={{ transform: "translateY(0px)" }}
          transition={{  duration: 0.5, type: "spring" }}
        >
          <p>We are your one step solution!</p>
        </motion.div>
      </Grid>
    </Grid>
  );
};

export default Solutions;

import React from "react";
import "./Header.css";
import Grid from '@mui/material/Grid2';

import { motion } from "framer-motion";

const Partners = () => {
  return (
    <div className="__section">
        <Grid container spacing={0} alignContent={'center'}>
            <Grid size={{ sm: 12 }} className="leftGridPartner">
                Partners
            </Grid>
        </Grid>
        <Grid container spacing={5} alignContent={'center'} justifyContent={'center'} className="mainSection">
            <Grid size={{ sm: 12, md: 3 }} className="partnerSection" alignContent={"center"}>
                <div className="partnerBox">TADA</div>
            </Grid>
            <Grid size={{ sm: 12, md: 3 }} className="partnerSection" alignContent={"center"}>
                <div className="partnerBox"> TADA </div>
            </Grid>
            <Grid size={{ sm: 12, md: 3 }} className="partnerSection" alignContent={"center"}>
                <div className="partnerBox"> TADA </div>
            </Grid>
        </Grid>
    </div>
  );
};

export default Partners;
